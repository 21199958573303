import React, { useState, useEffect } from "react"
import styled from "styled-components"
import Layout from "../layout/layout"
import Fade from "react-reveal/Fade"
import CircleButton from "../components/button/circleButton"
import { graphql } from "gatsby"

const Container = styled.div`
  display: grid;
  grid-template-columns: 31.33% 31.33% 31.33%;
  width: 90%;
  margin: 20px auto;
  grid-column-gap: 3%;
  column-gap: 3%;

  .link-post-wrapper {
    text-decoration: none;
    color: ${({ theme }) => theme.primaryColor};
  }
  .post-content {
    color: ${({ theme }) => theme.text};
  }

  @media (max-width: 1420px) {
    grid-template-columns: auto auto;
  }
  @media (max-width: 1080px) {
    display: block;
    width: 70%;
  }
`;

const PostInfo = styled.div`
  margin-top: 10px;
  font-size: 16px;
  color: gray;
`;

export default function Blog({ data }) {

    return (
        <Layout pageTitle="Blog - Eugenio Maucieri" pageDescription="Miglior studio dentistico" pageKeywords="Salute, Denti, Chirurgia" pageAuthor="Eugenio Maucieri">
            <Fade left duration={1000}>
                <h1>Blog</h1>
            </Fade>
            <Container>
                {data.allWpPost.edges.map(({ node }) => (
                    <div>
                        <h3><b>{node.title}</b></h3>
                        {
                            node?.featuredImage?.node.sourceUrl && <img src={node.featuredImage.node.sourceUrl} alt={node.slug} width="100%" height="auto"></img>
                        }
                        <div dangerouslySetInnerHTML={{ __html: node.excerpt }} />
                        <PostInfo>Pubblicato il: {node.date}</PostInfo>
                        <CircleButton children={"Leggi Articolo"} link={`${node.slug}`} open dark />
                    </div>
                ))}
            </Container>
        </Layout>
    )
}

export const pageQuery = graphql`
  query {
    allWpPost(sort: { fields: [date] }) {
      edges {
        node {
          title
          excerpt
          slug
                 date(formatString: "MM/DD/YYYY")
          featuredImage {
            node {
              sourceUrl
            }
          }
        }
      }
    }
  }
  `;